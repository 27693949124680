import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Navbar = () => {
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const toggleNavbar = () => {
    setIsNavbarActive(!isNavbarActive);
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("[data-header]");
      if (window.scrollY > 100) {
        header.classList.add("active");
      } else {
        header.classList.remove("active");
      }
    };

    window.addEventListener("scroll", handleScroll);

    const sections = document.querySelectorAll("section");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <header className="header" data-header>
      <div className="container">
        <Link to="/" className="logo">
          {/* Ganti gambar logo dengan teks */}
          <span className="logo-text">Sewa Alphard Bali</span>
        </Link>

        <nav className={`navbar ${isNavbarActive ? 'active' : ''}`} data-navbar>
          <div className="navbar-top">
            <Link to="/" className="logo">
              {/* Ganti gambar logo dengan teks */}
              <span className="logo-text">Sewa Alphard Bali</span>
            </Link>

            <button className="nav-close-btn" aria-label="close menu" onClick={toggleNavbar}>
              <i className="fas fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <ul className="navbar-list">
            <li>
              <a
                href="#hero-section"
                className={`navbar-link ${activeSection === 'hero-section' ? 'active' : ''}`}
                onClick={toggleNavbar}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#service-section"
                className={`navbar-link ${activeSection === 'service-section' ? 'active' : ''}`}
                onClick={toggleNavbar}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#card-slider"
                className={`navbar-link ${activeSection === 'card-slider' ? 'active' : ''}`}
                onClick={toggleNavbar}
              >
                Mobil Rental
              </a>
            </li>
            <li>
              <a
                href="#tour-service"
                className={`navbar-link ${activeSection === 'tour-service' ? 'active' : ''}`}
                onClick={toggleNavbar}
              >
                Destination
              </a>
            </li>
            <li>
              <a
                href="#service-vvip"
                className={`navbar-link ${activeSection === 'service-vvip' ? 'active' : ''}`}
                onClick={toggleNavbar}
              >
                Service
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className={`navbar-link ${activeSection === 'contact' ? 'active' : ''}`}
                onClick={toggleNavbar}
              >
                Contact
              </a>
            </li>
          </ul>

          <div className="wrapper">
            <a href="mailto:info@email.com" className="contact-link">info@email.com</a>
          </div>

          <ul className="social-list">
            <li>
              <a href="#" className="social-link">
                <ion-icon name="logo-twitter"></ion-icon>
              </a>
            </li>
            <li>
              <a href="#" className="social-link">
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
            </li>
            <li>
              <a href="#" className="social-link">
                <ion-icon name="logo-dribbble"></ion-icon>
              </a>
            </li>
            <li>
              <a href="#" className="social-link">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>
            <li>
              <a href="#" className="social-link">
                <ion-icon name="logo-youtube"></ion-icon>
              </a>
            </li>
          </ul>
        </nav>

        <Link to="/car-list" className="btn " onClick={toggleNavbar}>Get your car today</Link>

        <button className="nav-open-btn" aria-label="open menu" onClick={toggleNavbar}>
          <i className="fas fa-bars" aria-hidden="true"></i>
        </button>

        <div className={`overlay ${isNavbarActive ? 'active' : ''}`} onClick={toggleNavbar}></div>
      </div>
    </header>
  );
};

export default Navbar;
