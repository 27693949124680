import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faWifi, faCookieBite, faGlassWater, faGasPump, faUser } from '@fortawesome/free-solid-svg-icons';

const cars = [
  {
    name: 'inova zenix hybrid',
    passengers: 5,
    wifi: false,
    snack: false,
    water: false,
    price: 'IDR 1.300.000 / 12 jam',
    image: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/alpa.jpeg',
    petrolType: 'Bensin Termasuk',
    drive: true,
    duration: '12 jam'
  },
  {
    name: 'Alphard',
    passengers: 4,
    wifi: true,
    snack: true,
    water: true,
    price: 'IDR 2.400.000 / 12 jam',
    image: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/WhatsApp%20Image%202024-07-30%20at%2015.40.19.jpeg',
    petrolType: 'Bensin Termasuk',
    drive: true,
    duration: '12 jam'
  },
  {
    name: 'Hi-ace',
    passengers: 8,
    wifi: true,
    snack: true,
    water: true,
    price: 'IDR 2.700.000 / 12 jam',
    image: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/hiace.jpeg',
    petrolType: 'bensin Termasuk',
    drive: true,
    duration: '12 jam'
  }
];

const whatsappNumber = '6281339907435'; // Ganti dengan nomor WhatsApp Anda

const ProjectSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Durasi animasi dalam milidetik
    });
  }, []);

  const handleOrderClick = (carName, carPrice, carDuration) => {
    const message = `Halo, saya ingin memesan ${carName} dengan harga ${carPrice} untuk durasi ${carDuration}. Apakah tersedia?`;
    const encodedMessage = encodeURIComponent(message);
    const waUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodedMessage}`;
    window.open(waUrl, '_blank');
  };

  const handleViewAllCars = () => {
    // Replace with the URL of your page displaying all cars
    const allCarsUrl = '/car-list';
    window.location.href = allCarsUrl;
  };

  return (
    <section className="section project" aria-labelledby="project-label">
      <div className="container">
        <p className="section-subtitle" id="project-label">Penawaran Sewa Mobil Terpopuler</p>
        <h2 className="h2 section-title">Penawaran Sewa Mobil Paling Populer</h2>
        <div className="grid-list mt-5">
          {cars.map((car, index) => (
            <div 
              key={index} 
              className="flex-container" 
              data-aos="fade-up" 
              data-aos-delay={index * 200} // Menambahkan delay bertahap untuk animasi
            >
              <div className="project-card">
                <figure className="card-banner img-holder">
                  <img src={car.image} alt={car.name} className="img-cover" />
                </figure>
                <div className="card-content">
                  <h3 className="h3">{car.name}</h3>
                  <div className="card-icons">
                    <div className="icon-group">
                      <i><FontAwesomeIcon icon={faCar} /></i>
                      <span>{car.passengers} Penumpang</span>
                    </div>
                    <div className="icon-group">
                      <i><FontAwesomeIcon icon={faWifi} /></i>
                      <span>{car.wifi ? 'Wi-Fi Tersedia' : 'Tanpa Wi-Fi'}</span>
                    </div>
                    <div className="icon-group">
                      <i><FontAwesomeIcon icon={faCookieBite} /></i>
                      <span>{car.snack ? 'Snack Termasuk' : 'Tanpa Snack'}</span>
                    </div>
                    <div className="icon-group">
                      <i><FontAwesomeIcon icon={faGlassWater} /></i>
                      <span>{car.water ? 'Air Minum Termasuk' : 'Tanpa Air Minum'}</span>
                    </div>
                    <div className="icon-group">
                      <i><FontAwesomeIcon icon={faGasPump} /></i>
                      <span>{car.petrolType}</span>
                    </div>
                    <div className="icon-group">
                      <i><FontAwesomeIcon icon={faUser} /></i>
                      <span>{car.drive ? 'Supir Termasuk' : 'Tanpa Supir'}</span>
                    </div>
                  </div>
                  <hr className="card-divider" />
                  <div className="card-bottom">
                    <div className="card-price">{car.price}</div>
                    <button 
                      className="card-link" 
                      onClick={() => handleOrderClick(car.name, car.price, car.duration)}
                    >
                      Pesan Sekarang
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-5">
          <button className="btn btn-secondary" onClick={handleViewAllCars}>
            Lihat Semua Mobil
          </button>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
