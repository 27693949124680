import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar.jsx';
import HeroSection from './components/HeroSection.jsx';
import ServiceSection from './components/services.jsx';
import CardSlider from './components/CardSlider.jsx';
import FloatingWhatsAppIcon from './components/FloatingWhatsAppIcon.jsx'; 
import Fouter from './components/fouter.jsx';
import ServiceVVip from './components/atoms/service.jsx';
import CarListPage from './components/page/CarListPage.js'; 
import ServiceDetailPage from './components/page/ServiceDetailVVIP.js';
import ServiceDetailPageair from './components/page/ServiceDetailPageAirport';
import ServiceDetailPageasport from './components/page/serviceDetaildSprot';
import TourService from './components/PaketTourSection.js';
import GallerySection from './components/GallerySection'; 
import OfferSection from './components/OfferSection.js'
import Tourcostom from './components/page/CreateTour.js'


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          {/* Halaman Utama */}
          <Route path="/" element={
            <>
              <section id="hero-section">
                <HeroSection />
              </section>
              <section id="service-section">
                <ServiceSection />
              </section>
              <section id="card-slider">
                <CardSlider />
              </section>
              <section id="service-vvip">
                <ServiceVVip />
              </section>
              <section id="tour-service">
                <TourService />
              </section>
              <section id="gallery-section">
                <GallerySection /> {/* Tambahkan GallerySection */}
              </section>
              <section id= "paket">
              <OfferSection />
              </section>
             
              <section id="floating-whatsapp-icon">
                <FloatingWhatsAppIcon />
              </section>
            </>
          } />
          {/* Halaman Daftar Mobil */}
          <Route path="/car-list" element={<CarListPage />} />
          {/* Halaman Detail Layanan */}
          <Route path="/services/car-rental-charter" element={<CarListPage />} />
          {/* <Route path="/services/bus-charter" element={<ServiceDetailPage />} /> */}
          <Route path="/services/rental-hiace" element={<ServiceDetailPage />} />
          <Route path="/services/sport-car" element={<ServiceDetailPageasport />} />
          <Route path="/services/airport" element={<ServiceDetailPageair />} />
          <Route path="/services/vip-airport" element={<ServiceDetailPage />} />
          <Route path="/cotum-tour" element={<Tourcostom />} />
        </Routes>
        <Fouter />
      </div>
    </Router>
  );
}

export default App;
