import React, { useState, useEffect } from 'react';
import { FaCar, FaBus, FaPlane, FaBolt, FaCrown, FaThLarge } from 'react-icons/fa';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { servicesData } from './data/servicesData.js';
import './TransportServices.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const categoryIcons = {
  All: <FaThLarge />,
  Rental: <FaCar />,
  Charter: <FaBus />,
  Airport: <FaPlane />,
  Sport: <FaBolt />,
  VIP: <FaCrown />
};

const ServicesSection = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [showAll, setShowAll] = useState(false);
  const [numVisible, setNumVisible] = useState(3);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const slider = document.querySelector('.swiper-container');
    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const handleMouseUp = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; // Scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    };

    slider.addEventListener('mousedown', handleMouseDown);
    slider.addEventListener('mouseleave', handleMouseLeave);
    slider.addEventListener('mouseup', handleMouseUp);
    slider.addEventListener('mousemove', handleMouseMove);

    return () => {
      slider.removeEventListener('mousedown', handleMouseDown);
      slider.removeEventListener('mouseleave', handleMouseLeave);
      slider.removeEventListener('mouseup', handleMouseUp);
      slider.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleFilterChange = (category) => {
    setSelectedCategory(category);
    setShowAll(false);
    setNumVisible(3);
  };

  const filteredServices = selectedCategory === 'All'
    ? servicesData
    : servicesData.filter(service => service.categories.includes(selectedCategory));

  const servicesToShow = showAll || selectedCategory !== 'All' ? filteredServices : filteredServices.slice(0, numVisible);

  const hasMoreCards = filteredServices.length > numVisible;

  const handleShowMore = () => {
    setNumVisible(numVisible + 3);
  };

  return (
    <section className="container py-5 bg-custom-color">
      <div className="text-center mb-5" data-aos="fade-up">
        <p className="text-muted d-md-none">Geser untuk melihat lebih banyak kategori</p>
        <div className="swiper-container d-md-none position-relative" style={{ overflowX: 'scroll', display: 'flex' }}>
          {['All', 'Rental', 'Airport', 'Sport', 'VIP'].map(category => (
            <div
              key={category}
              className={`swiper-slide card m-2 p-3 text-center ${selectedCategory === category ? 'border-primary' : ''}`}
              style={{ cursor: 'pointer', width: '120px', flexShrink: 0 }}
              onClick={() => handleFilterChange(category)}
              data-aos="fade-up"
            >
              <div className="mb-2">{categoryIcons[category]}</div>
              <div>{category}</div>
            </div>
          ))}
        </div>

        <div className="d-none d-md-flex justify-content-center flex-wrap mb-4">
          {['All', 'Rental', 'Airport', 'Sport', 'VIP'].map(category => (
            <div
              key={category}
              className={`card m-2 p-3 text-center ${selectedCategory === category ? 'border-primary' : ''}`}
              style={{ cursor: 'pointer', width: '120px' }}
              onClick={() => handleFilterChange(category)}
              data-aos="fade-up"
            >
              <div className="mb-2">{categoryIcons[category]}</div>
              <div>{category}</div>
            </div>
          ))}
        </div>
      </div>

      <TransitionGroup className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {servicesToShow.map(service => (
          <CSSTransition key={service.id} timeout={300} classNames="fade">
            <div className="col" data-aos="fade-up">
              <div className="card shadow-sm border-0 rounded-3 overflow-hidden h-100">
                <img src={service.imgSrc} className="card-img-top" alt={service.title} />
                <div className="card-body d-flex flex-column">
                  <h3 className="card-title mb-3 text-left">{service.title}</h3>
                  <p className="card-text text-muted flex-grow-1 text-left">{service.description}</p>
                  <Link to={service.link} className="btn btn-primary mt-2 align-self-end">Pelajari Lebih Lanjut</Link>
                </div>
              </div>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>

      {selectedCategory === 'All' && hasMoreCards && (
        <div className="text-center mt-4" data-aos="fade-up">
          <button className="btn btn-secondary" onClick={handleShowMore}>Lihat Lagi</button>
        </div>
      )}
    </section>
  );
};

export default ServicesSection;
