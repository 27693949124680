export const servicesData = [
  {
    id: 1,
    categories: ['Rental', 'Charter'],
    title: 'Bali Car Rental / Charter',
    description: 'Kami Menyediakan Jasa Sewa Mobil Plus Sopir Di Bali, Dengan Memberikan Service Yang Terbaik Bagi Pelanggan Dan Tamu, Baik Yang Berlibur Ataupun Punya Bisnis Di Bali. Kami Memberikan Harga Yang Murah/Bagus Tanpa Mengurangi Service Kami.',
    imgSrc: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/rentall.jpeg',
    link: '/services/car-rental-charter'
  },
  // {
  //   id: 2,
  //   categories: ['Charter'],
  //   title: 'Bali Bus Charter',
  //   description: 'Bali Bus Charter Menyediakan Beberapa Jenis Bus Pariwisata Untuk Group Service, Dengan Kondisinya Bagus, Bersih Dan Sopir Yang Sudah Berpengalaman Ke Obyek -Wisata Di Pulau Bali.',
  //   imgSrc: 'https://via.placeholder.com/600x400',
  //   link: '/services/bus-charter'
  // },
  {
    id: 3,
    categories: ['Airport'],
    title: 'Rental Hiace Bali',
    description: 'Dengan Mobil Yang Bersih Dan Nyaman Dengan Sopir Yang Sudah Pasti Berpengalaman Akan Memastikan Bahwa Kedatangan Anda Di Bali Menyenangkan Dan Nyaman.',
    imgSrc: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/g2cc.jpeg',
    link: '/services/airport'
  },
  {
    id: 4,
    categories: ['Sport'],
    title: 'Sport Car Bali ',
    description: 'Bali Alphard Rental, Menawarkan Pilihan Mobil Sport / Sport Car Bali, Dengan Tampilan Mewah Terbaik Untuk Disewa Seperti BMW, Porsche, Mini Cooper. Disini Kami Menawarkan Sewa Sport Car Dengan Sopir Dan Juga Dengan Tanpa Sopir / Lepas Kunci.',
    imgSrc: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/sport.jpeg',
    link: '/services/sport-car'
  },
  {
    id: 5,
    categories: ['VIP', 'Airport'],
    title: 'Bali VIP Airport Service',
    description: 'Adalah Pelayanan Kelas VIP Untuk Penanganan Kedatangan Atau Keberangkatan Tamu VIP Di Bandara Tanpa Harus Memerlukan Waktu Yang Lama.',
    imgSrc: 'https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/vvip%20bandara.jpeg',
    link: '/services/vip-airport'
  },

 


];
