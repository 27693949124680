import React from "react";
import "./Footer.css"; // Import the CSS file

const Footer = () => {
  return (
    <div className="full-width-footer">
      <footer
        className="text-center text-lg-start text-white"
        style={{ backgroundColor: "#1c2331" }}
      >
        {/* Section: Links */}
        <section>
          <div className="container text-center text-md-start mt-5 footer-content">
            {/* Grid row */}
            <div className="row mt-3">
              {/* Grid column */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                {/* Content */}
                <h6 className="text-uppercase fw-bold">Sewa Alphard Bali</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                Kami  Sewa Alphard Bali, penyedia jasa sewa Alphard mewah dan paket wisata di Bali. Dengan pengalaman bertahun-tahun,
                 kami berkomitmen untuk memberikan layanan terbaik kepada setiap klien.
                </p>
              </div>
              {/* Grid column */}

              {/* Grid column */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold">Products</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <a href="/car-list" className="text-white">
                    Car Rentals
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-white">
                    Charter Services
                  </a>
                </p>
                <p>
                  <a href="/services/rental-hiace" className="text-white">
                    Airport Transfers
                  </a>
                </p>
                <p>
                  <a href="/services/vip-airport" className="text-white">
                    VIP Services
                  </a>
                </p>
              </div>
              {/* Grid column */}

              {/* Grid column */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold">Useful links</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <a href="/car-list" className="text-white">
                    Car List
                  </a>
                </p>
                <p>
                  <a href="/services" className="text-white">
                    Services
                  </a>
                </p>
                <p>
                  <a href="/about-us" className="text-white">
                    About Us
                  </a>
                </p>
                <p>
                  <a href="/contact-us" className="text-white">
                    Contact Us
                  </a>
                </p>
              </div>
              {/* Grid column */}

              {/* Grid column */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                {/* Links */}
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px",
                  }}
                />
                <p>
                  <i className="fas fa-home mr-3"></i> Jl. Raya Sesetan Gg. Klp. No.12, Sesetan, Denpasar Selatan, Kota Denpasar, Bali 80225
                </p>
                <p>
                  <i className="fas fa-envelope mr-3"></i> info@drivewise.com
                </p>
                <p>
                  <i className="fas fa-phone mr-3"></i> +62 878-5078-5642
                </p>
                <p>
                  <i className="fas fa-phone mr-3"></i> +62 813-3990-7435
                </p>
              </div>
              {/* Grid column */}
            </div>
            {/* Grid row */}
          </div>
        </section>
        {/* Section: Links */}

        {/* Copyright */}
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © 2024 Drivewise | Developed by AnggaPuspa
        </div>
        {/* Copyright */}
      </footer>
    </div>
  );
};

export default Footer;
