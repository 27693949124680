import React from 'react';


const HeroSection = () => {
  return (
    <section className="hero-section d-flex align-items-center" style={{ minHeight: '100vh', backgroundColor: '#f8f9fa' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="hero-content col-md-10">
              <h1 className="display-4 mb-4">Bali di Ujung Jari Anda. Sewa Mobil dan Paket Wisata Lengkap Hanya di Sini.</h1>
              <p className="lead mb-4">
              Bali Menanti dalam Kemewahan. Jelajahi Pulau Dewata dengan Armada Kendaraan Eksklusif Kami. Pilihan Favorit Para Pejabat dan Selebriti.
              </p>
              <a href="/car-list" className="btn btn-secondary">Pesan Sekarang</a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="hero-image">
              <img src="https://raw.githubusercontent.com/AnggaPuspa/foto-rental/main/3492567-removebg-preview.png" alt="Alphard" className="img-fluid rounded shadow-lg" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
