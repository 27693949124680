import React, { useEffect } from 'react';
import './OfferSection.css'; // Import custom CSS for styling
import AOS from 'aos';
import 'aos/dist/aos.css';

const OfferSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="section project" aria-labelledby="project-label">
      <div className="offer-section" data-aos="fade-up">
        <div className="offer-background">
          <div className="offer-overlay"></div>
          <div className="offer-content">
            <h2 className="offer-title">Belum Menemukan Tour yang Cocok?</h2>
            <p className="offer-text">
              Yuk, buat tour yang sesuai dengan keinginan Anda! Temukan pengalaman yang tidak terlupakan dengan penawaran khusus kami.
            </p>
            <div className="mt-4">
              <a href="/cotum-tour" className="btn btn-secondary">Custom paket tour kamu sendiri</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OfferSection;
